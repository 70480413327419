import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Loading from '../Loading/Loading';
import util from 'util';

const Login = (props) => {
  const { user, userActions } = props;
  const { register, handleSubmit, errors } = useForm();
  const [ message, setMessage ] = useState(null);
  
  const handlePostThen = (response) => {
    console.log(response);
    if (response.status === 200) {
      var userData = response.data;
      if (userData !== undefined) {
        localStorage.setItem('jwt', userData.jwt);
        userActions.login({
          userId: userData.user_id,
          userName: userData.user_name,
          userShort: userData.user_short,
          userClass: String(userData.user_class),
          shopId: userData.shop_id,
          userToken: userData.jwt,
        });
      } else {
        setMessage('ユーザが見つかりません');
        userActions.logout();
      }
    } else {
      setMessage('ユーザが見つかりません');
      userActions.logout();
    }
  }
  
  const handlePostCatch = (err) => {
    console.log(util.inspect(err));
    setMessage(err.response.data);
    userActions.logout();
  }

  const onSubmit = async (data) => {
    userActions.fetchLogin();
    const user_params = {
      user_id: data.userId,
      password: data.passWord,
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login/`, user_params);
      handlePostThen(response);
    } catch (err) {
      handlePostCatch(err);
    }
  }

  const renderSubmit = () => {
    return user.isFetching ? <Loading /> : <input type="submit" value="ログイン" />;
  }

  return (
    <div className='container'>
      <div className='row m-2'>
        <div className="col text-center">
          <h3> ログイン </h3>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-2">
          <div className="col text-center">
            <input name="userId" placeholder="ユーザID" ref={register({ required: true })} />
          </div>
        </div>
        <div className="row m-2">
          <div className="col text-center">
            <input type="password" name="passWord" placeholder="パスワード" ref={register({ required: true })} />
          </div>
        </div>
        {errors.userId && <div className="row m-2">
          <div className="col text-center">
            <span className="text-danger">ユーザIDは必須項目です</span>
          </div>
        </div>}
        {errors.passWord && <div className="row m-2">
          <div className="col text-center">
            <span className="text-danger">パスワードは必須項目です</span>
          </div>
        </div>}
        {message && <div className="row m-2">
          <div className="col text-center">
            <span className="text-danger">{message}</span>
          </div>
        </div>}
        <div className="row m-2">
          <div className="col text-center">
            {renderSubmit()}
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;