import { useRef, useEffect, useState, useCallback } from 'react';
export function useUnmountRef() {
  const unmountRef = useRef(false);

  useEffect(
    () => () => {
      unmountRef.current = true;
    },
    []
  );

  return unmountRef;
};

export function useSafeState(unmountRef, defaultValue) {
  const [state, changeState] = useState(defaultValue);
  const wrapChangeState = useCallback(
    (v) => {
      if (!unmountRef.current) {
        changeState(v);
      }
    },
    [changeState, unmountRef]
  );

  return [state, wrapChangeState];
};