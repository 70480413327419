import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import ArrowOn from '../Images/arrow_on.png';
import ArrowOff from '../Images/arrow_off.png';
import logo from '../Images/logo.svg';
import Modal from 'react-modal';

Modal.setAppElement("#root");

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      my_order: null,
      entry_type: [],
      payment: null,
      class_id: null,
      method: null,
      message: null,
      open_data: null,
      wait_count: null,
      call_data: null,
      pre_data: null,
      ready: false,
      sleeping: false,
      param: {},
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '50%',
        bottom: 'auto',
        marginTop: '-25%',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };

    this.refreshOrder = this.refreshOrder.bind(this);
    this.change = this.change.bind(this);
    this.handleRequestClick = this.handleRequestClick.bind(this);
    this.setReady = this.setReady.bind(this);
  }

  async componentDidMount() {
    this.refreshOrder();
    this.refreshOrderCount();
    this.intervalCount = setInterval(() => {
      this.refreshOrderCount();
    }, 10000);
    this.disableBounceScroll();
  }

  async componentWillUnmount() {
    clearInterval(this.intervalCount);
  }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        el = el.parentElement;
      }

      if (noScroll) {
        if(e.cancelable){
          e.preventDefault();
        }
      }

      touchY = moveY;
    }, { passive: false });
  }

  change(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  // DB取得
  async refreshOrder() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalOrder) {
        this.intervalOrder = setInterval(() => {
          this.refreshOrder();
        }, 500);
      }
    } else {
      clearInterval(this.intervalOrder);
      const reg_params = {
        "operator": "and",
        "where": [
          {
            "operator": "notIn",
            "attr": "status",
            "val": [constClass.STATUS.FIN, constClass.STATUS.UCCL, constClass.STATUS.ACCL, constClass.STATUS.CCL]
          },
          {
            "line_id": this.props.liff_access_token
          }
        ]
      }
      try {
        const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params)).data; //自分のorder取得
        const entry_type = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/entry_type/`)).data; //入力項目取得
        const setting = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`)).data; //設定マスタ取得

        this.setState({
          my_order: my_order.find(o => o.line_id === this.props.liff_user_id),
          entry_type,
          setting,
          disabled: false
        });
        var page;

        if (this.state.my_order === undefined) { // 未受付
          page = constClass.REQUEST;
        } else if (this.props.page === constClass.CANCEL) { // 受付済かつキャンセル
          page = this.props.page;
        } else { // 受付済かつキャンセル以外
          page = null;
        }
        this.props.setPage(page);
        if (this.props.page === constClass.CANCEL) {
          this.submitCancel('CCL');
        }
        this.setReady();
      } catch (err) {
        this.setState({ sleeping: true });
        // window.alert(err.message);
      }
    }
  }


  async refreshOrderCount() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    try {
      const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/`)).data;
      // var bus_pre = data.pre_data.filter(p => p.class === constClass.CLASS.BUS);
      // var bus_blank = new Array(10 - (bus_pre ? bus_pre.length : 0)).fill({});
      // var train_pre = data.pre_data.filter(p => p.class === constClass.CLASS.TRAIN);
      // var train_blank = new Array(10 - (train_pre ? train_pre.length : 0)).fill({});
      var abs_data;
      if (data.abs_data && data.abs_data.length <= constClass.MAX_ABS) {
        var abs_blank = new Array(constClass.MAX_ABS - (data.abs_data ? data.abs_data.length : 0)).fill({});
        abs_data = data.abs_data.concat(abs_blank);
      } else {
        abs_data = data.abs_data.filter((dat, idx) => idx < constClass.MAX_ABS);
      }
      // var call_data;
      // if (data.call_data && data.call_data.length <= constClass.MAX_CALL) {
      //   var call_blank = new Array(constClass.MAX_CALL - (data.call_data ? data.call_data.length : 0)).fill({});
      //   call_data = data.call_data.concat(call_blank);
      // } else {
      //   call_data = data.call_data.filter((dat, idx) => idx < constClass.MAX_CALL);
      // }
      this.setState({
        wait_count: data.wait_count,
        call_data: data.call_data,
        abs_data: abs_data,
        open_data: data.open_data
      });

      this.setReady();
    } catch (err) {
      // カウントが取れない場合は休止中表示にしない（オーダー未取得時に合わせる）
      // this.setState({sleeping: true});
      // window.alert(err.message);
    }
  }

  setReady() {
    if (this.state.my_order !== null &&
      this.state.wait_count !== null &&
      this.state.call_data !== null &&
      this.state.abs_date !== null &&
      this.state.open_data !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }

  submitPayment(payment) {
    this.setState({ payment, message: null });
  }

  submitClass(class_id) {
    this.setState({ class_id, message: null });
  }

  submitMethod(method) {
    this.setState({ method, message: null });
  }

  addAdultCount() {
    var count = this.state.adult_count + 1;
    console.log(count);
    this.setState({ adult_count: count });
  }

  addChildCount() {
    var count = this.state.child_count + 1;
    this.setState({ child_count: count });
  }

  subAdultCount() {
    var count = (this.state.adult_count - 1 < 0 ? 0 : this.state.adult_count - 1);
    console.log(count);
    this.setState({ adult_count: count });
  }

  subChildCount() {
    var count = (this.state.child_count - 1 < 0 ? 0 : this.state.child_count - 1);
    this.setState({ child_count: count });
  }

  submitParam(key, value) {
    var _param = this.state.param;
    _param = { ..._param, [key]: value }
    this.setState({ param: _param });
  }

  checkDataInvalid() {
    const type = this.state.entry_type.filter(type => type.required === 1);
    var valid = type.reduce((flag, item) => flag && this.state.param[item.entry_type] !== null && this.state.param[item.entry_type] !== undefined, true)
    return !valid
  }

  getTimeString(time) {
    return `${Math.floor(Number(time) / 100)}:${time.substr(2,2)}`;
  }

  getSetting(type, def = null) {
    return this.state.setting.find(s => s.setting_type === type) ? this.state.setting.find(s => s.setting_type === type).setting_val : def;
  }

  async submit() {
    if (this.checkDataInvalid() || this.state.disabled) {
      return;
    }
    if (window.confirm(`受付番号を発行してもよろしいですか？`)) {//\r\n${constClass.PAYMENT_NAME[this.state.payment]}・${constClass.CLASS_NAME[this.state.class_id]}・${constClass.METHOD_NAME[this.state.method]}
      this.setState({ disabled: true });
      var data = {
        'shop_id': constClass.SHOP_ID,
        'line_id': this.props.liff_access_token,
      };
      // data[constClass.COLUMN.ADULT] = this.state.adult_count;
      // data[constClass.COLUMN.CHILD] = this.state.child_count;
      this.state.entry_type.forEach(type => {
        data[type.entry_type] = this.state.param[type.entry_type]; // typeで指定した列にdataのparam_nameの値をセット
      })
      try {
        // const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data)).data;
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data);
        this.setState({ message: `受付番号を発行しました`, param: {} });
      } catch (err) {
        this.setState({ message: err });
      }
      this.refreshOrder();
      // if (window.liff.isInClient()) {
      //   window.liff.closeWindow();
      // }
    }
  }

  async submitCancel(command = null) {
    if (this.state.my_order === null || this.state.my_order === undefined || this.state.disabled) {
      return;
    }
    if (window.confirm(`キャンセルしてもよろしいですか？\r\n受付番号：${this.state.my_order.receipt_num}番`)) {
      this.setState({ disabled: true });
      const data = {
        status: constClass.STATUS.UCCL,
        'line_id': this.props.liff_access_token,
      };
      try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${this.state.my_order.order_id}`, data);
        this.setState({ message: `キャンセルしました`, kind: null })
      } catch (err) {
        this.setState({ message: err });
      }
      this.props.setPage(constClass.REQUEST);
      this.refreshOrder();
      if (window.liff.isInClient()) {
        window.liff.closeWindow();
      }
    } else {
      if (command === 'CCL') {
        if (window.liff.isInClient()) {
          window.liff.closeWindow();
        }
      }
    }
  }

  handleRequestClick() {
    this.props.setPage(constClass.REQUEST);
  }

  renderHeader() {
    return (
      <header className="header">
        <div className="row mx-0">
          <div className="col section section-top">
            <span>{this.state.my_order !== undefined ? `受付番号確認` :
              this.props.page === constClass.REQUEST ? `順番待ち` : `呼出し状況`}</span>
          </div>
        </div>
      </header>
    );
  }

  renderReceipt() {
    var text_class = `text-${constClass.COLOR.BUS}`;
    var border_class = `border-${constClass.COLOR.BUS}-bold`;
    return (
      <div className="row mx-0 my-5">
        <div className="col">
          <div className="row">
            <div className="col text-center">
              <h4>あなたの受付番号は</h4>
            </div>
          </div>
          <div className="row p-1 justify-content-center">
            <div className={`col-auto text-center ${text_class} mx-1 py-1 w-50 bg-white border ${border_class}`}>
              <h4 className="mb-0 font-weight-bold">{this.state.my_order.receipt_num}</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSection() {
    return (
      <div className="row mx-0">
        <div className="col section">
          <span>{`呼出し状況`}</span>
        </div>
      </div>
    );
  }

  // 西鉄旅行ロゴ
  // renderLogo() {
  //   return (     
  //     <div className="row mx-0 my-3 px-3-env">
  //       <div className="col">         
  //       </div>
  //       <div class = "center"><img src="images/ntc_logo.png"></img></div>
  //     </div>
  //   );
  // }

  // キャラクター
  // renderParent() {
  //   return (     
  //     <div className="row mx-0 my-3 px-3-env">
  //       <div className="col">         
  //       </div>
  //       <div class = "center"><img src="images/img_parent.jpg"></img></div>
  //     </div>
  //   );
  // }

  renderCount() {
    return (
      <div className="row mx-0 my-3 px-3-env">
        <div className="col">
          <div className="row">
            <div className="col text-center">
              <span>現在の待ち組数</span>
            </div>
          </div>
          <div className="row p-1">
            <div className="col text-center ml-2 mr-1">
              <div className={`row border border-${constClass.COLOR.BUS} bg-${constClass.COLOR.BUS}`}>
                <div className="col bg-white px-0 py-1">
                  <h5 className="mb-0 d-inline font-weight-bold">{this.state.wait_count.find(x => true) ? this.state.wait_count.find(x => true).count_order : 0}</h5>
                  <span className="d-inline small">組</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAbs() {
    // var bg_class = `bg-${constClass.COLOR.BUS}`;
    var text_class = `text-${constClass.COLOR.BUS}`;
    // var data;
    // var icon;
    // if (class_id === constClass.CLASS.BUS) {
    //   data = this.state.pre_data.bus;
    //   icon = IconBus;
    // } else if (class_id === constClass.CLASS.TRAIN) {
    //   data = this.state.pre_data.train;
    //   icon = IconTrain;
    // }

    return (
      <div className="px-0-env">
        {/* <div className="row mx-1 my-1">
          <div className={`col text-center ${bg_class} py-1 mx-0`}>
            <span className="align-middle">不在の方</span>
          </div>
        </div> */}
        <div className="row mx-1 my-1">
          {this.state.abs_data.map((p, idx) => (
            idx < (constClass.MAX_ABS / 2) ? (
              <div className={`col text-center ${text_class} px-1`} key={idx}>
                <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={idx}>
                  <span className="mb-0 ">{p.receipt_num ? p.receipt_num : '-'}</span>
                </div>
              </div>
            ) : null
          ))}
        </div>

        <div className="row mx-1 my-1">
          {this.state.abs_data.map((p, idx) => (
            idx >= (constClass.MAX_ABS / 2) ? (
              <div className={`col text-center ${text_class} px-1`} key={idx}>
                <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={idx}>
                  <span className="mb-0 ">{p.receipt_num ? p.receipt_num : '-'}</span>
                </div>
              </div>
            ) : null
          ))}
        </div>
      </div>
    );
  }

  renderCall() {
    // var bg_class = `bg-${constClass.COLOR[class_id]}`;
    // var text_class = `text-${constClass.COLOR[class_id]}`;    
    return (
      <div className="row mx-1">
        {/* {this.state.call_data.filter(c => c.user_class === Number(class_id)).map((data) => ( */}
        {this.state.call_data.map((data) => (
          <div key={data.user_id} className="col-6 p-0 mx-0 my-1">
            <div className="row p-0 m-0">
              <div className={`col-6 text-center text-primary py-1 px-0 align-self-center`}>
                <span>{data.user_short}</span>
              </div>

              <div className={`col-6 text-center text-primary bg-white border border-cell my-1`}>
                <span>{data.receipt_num ? data.receipt_num : '-'}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderCounter() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col section-dark px-0">
              <p className="my-0">お呼出し中の受付番号</p>
            </div>
            <div className="col-12 py-2 text-white bg-white">
              {this.renderCall()}
            </div>
          </div>
          <div className="row mx-0">
            <div className="col section-dark px-0">
              <p className="my-0">お呼出し済みでご不在のお客様</p>
            </div>
            <div className="col-12 text-white bg-white py-2">
              {this.renderAbs()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  //　入力項目（ボタン）
  renderSelectButton = (type, data) =>
    <div key={data.entry_seq} className="row mx-0 bg-white px-3-env pt-3 pb-3">
      <div className="col text-center bg-lightwhite p-1 ml-1">
        <button
          disabled={this.state.disabled}
          className={`btn ${this.state.param[type.entry_type] === data.entry_data ? "btn-active" : "btn-enable"} w-100 h-100 py-2`}
          onClick={() => { this.submitParam(type.entry_type, data.entry_data) }}>
          {data.entry_name}
        </button>
      </div>
    </div>

  renderRequest() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          {this.state.entry_type.map(type =>
            <div key={type.entry_type}>
              <div className="row mx-0">
                <div className="col section-dark py-0 line-height-2-2">
                  {/* <span className="mb-0 d-inline-block font-weight-bold align-middle pr-1 font-h4">&#10102;</span> */}
                  <span className="d-inline-block align-middle">{type.entry_type_name}</span>
                </div>
              </div>
              {type.m_entry_data.filter(d => d.disp_flag === constClass.DISP_FLAG.DISP_ON).map(data =>
                this.renderSelectButton(type, data)
              )}
              {/* 申し込み */}
              {/* {this.renderSelectButton(constClass.COUNTER.SUBSCRIPTION)} */}
              {/* お受け取り */}
              {/* {this.renderSelectButton(constClass.COUNTER.RECEIVE)} */}
              {/* 取消 */}
              {/* {this.renderSelectButton(constClass.COUNTER.CANCEL)} */}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderBottom() {
    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100">
          <div className="row mx-0">
            {(this.state.my_order !== undefined) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col text-center p-0">
                    <button
                      disabled={this.state.disabled}
                      className="btn-lg btn-submit-cancel w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitCancel() }}>
                      順番待ちをやめる
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.state.my_order === undefined) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col text-center p-0">
                    <button
                      disabled={this.checkDataInvalid() || this.state.disabled}
                      className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                      onClick={() => { this.submit() }}>
                      <span className="d-inline-block pr-1">申込む </span>
                      <img className="arrow mb-0 d-inline-block" src={this.checkDataInvalid() || this.state.disabled ? ArrowOff : ArrowOn} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    );
  }



  render() {
    return (
      this.state.sleeping ? <div className="liff-top bg-lightwhite font-weight-bold">
        <Modal isOpen={this.state.sleeping} style={this.modalStyle}>
          <div className="text-center section-white m-0 py-2">
            <h4>現在サービス停止中です</h4>
            <span></span><br /><br />
            <img className="w-50" src={logo} alt="" />
          </div>
        </Modal>
      </div> :
        this.state.ready ? <div className="liff-top bg-lightwhite font-weight-bold">
          {this.renderHeader()}
          <div className={`page-${(this.state.my_order !== undefined || this.props.page === constClass.REQUEST) ? "btn" : "full"}`}>
            {/* {this.state.my_order === undefined && this.renderLogo()} */}
            {/* {this.state.my_order === undefined && this.renderParent()} */}
            {this.state.my_order === undefined && this.renderCount()}
            {this.state.my_order !== undefined && this.renderReceipt()}
            {this.state.my_order !== undefined && this.renderSection()}
            {(this.state.my_order !== undefined || this.props.page !== constClass.REQUEST) && this.renderCounter()}
            {(this.state.my_order === undefined && this.props.page === constClass.REQUEST) && this.renderRequest()}
          </div>
          {(this.state.my_order !== undefined || this.props.page === constClass.REQUEST) && this.renderBottom()}
          {this.state.disabled && <Loading />}
          <Modal isOpen={this.state.my_order === undefined && this.props.page === constClass.REQUEST && this.state.open_data.open_line_flg === constClass.CLOSE} style={this.modalStyle}>
            <div className="text-center section m-0 py-2">
              <span>現在受付時間外です。</span><br />
              {this.state.setting &&
                <p className="mb-0">
                  LINEでの受付時間は以下の通りとなります<br />
                  {`平日：${this.getTimeString(this.getSetting(constClass.SETTING.CAL_LINE_DEFAULT_START))}～${this.getTimeString(this.getSetting(constClass.SETTING.CAL_LINE_DEFAULT_END))}`}<br />
                  {`土祝日：${this.getTimeString(this.getSetting(constClass.SETTING.CAL_LINE_HOLIDAY_START))}～${this.getTimeString(this.getSetting(constClass.SETTING.CAL_LINE_HOLIDAY_END))}`}
                </p>
              }
            </div>
          </Modal>
          <Modal isOpen={this.state.my_order === undefined && this.props.page === constClass.REQUEST && this.state.open_data.open_wait_flg === constClass.CLOSE} style={this.modalStyle}>
            <div className="text-center section-white m-0 py-2">
              <h4>すぐにご入店いただけます</h4>
              <span>ご来店ありがとうございます</span><br /><br />
              <img className="w-50" src={logo} alt="" />
            </div>
          </Modal>
        </div>
          : <Loading />)
  }
}

export default withRouter(Order);