import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import {useUnmountRef, useSafeState} from '../Common/SafeState';

// 窓口管理画面
const BackyardOrder = (props) => {
  const { user } = props;
  const unmountRef = useUnmountRef();
  const [orderData, setOrderData] = useSafeState(unmountRef, null);
  const [lockData, setLockData] = useSafeState(unmountRef, false);
  const { addToast } = useToasts();
  const [newNumber, setNewNumber] = useSafeState(unmountRef, null);
  const [countData, setCountData] = useSafeState(unmountRef, null);
  const [entryType, setEntryType] = useSafeState(unmountRef, []);

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "operator": "or",
      "where": [
        {
          "status": constClass.STATUS.REG
        },
        {
          "status": constClass.STATUS.PRE
        },
        {
          "status": constClass.STATUS.CALL
        },
        {
          "status": constClass.STATUS.WIP
        }
      ]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.order_id - b.order_id);
    setOrderData(data);

    const waitData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/`)).data;
    setCountData(waitData);
    const NewNumber = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/saiban/`)).data;
    setNewNumber(NewNumber.find(n => n.shop_id === constClass.SHOP_ID) ? NewNumber.find(n => n.shop_id === constClass.SHOP_ID).number + 1 : 1);
  }, [setOrderData, setCountData, setNewNumber]);

  const refreshMaster = useCallback(async () => {
    const entry_type = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/entry_type/`)).data; //入力項目取得
    setEntryType(entry_type);
  }, [setEntryType]);

  const handleStatusButtonClick = async (order_id, status) => {
    if (orderData.find(o => o.order_id === order_id).status === status) {
      return;
    }
    const params = {
      status,
      user_id: user.userId,
    };
    try {
      setLockData(true);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${order_id}`, params);
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      await refreshOrder();
      setLockData(false);
    }
  }

  // const renderButton = (data, status, statusName, additionalStatus = []) => {
  //   additionalStatus.push(status);
  //   statusName = [constClass.STATUS_NAME.CALL, constClass.STATUS_NAME.PRE].includes(statusName) && additionalStatus.includes(data.status) ? statusName + '中' : statusName;
  //   return (
  //     <button type="button"
  //       disabled={lockData}
  //       className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
  //       onClick={() => handleStatusButtonClick(data.order_id, status)}>
  //       {statusName}
  //     </button>
  //   )
  // }

    const renderButton = (data, status, statusName, additionalStatus = []) => {
    additionalStatus.push(status);
    statusName = [constClass.STATUS_NAME.CALL, constClass.STATUS_NAME.PRE, constClass.STATUS_NAME.WIP,].includes(statusName) && additionalStatus.includes(data.status) ? statusName + '中' : statusName;

    return (
      <button type="button"
        disabled={lockData || user.userClass === constClass.CLASS.OTHER || user.userClass === constClass.CLASS.CHECK}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
        onClick={() => handleStatusButtonClick(data.order_id, status)}>
        {statusName}
      </button>
    )
  }

  const renderData = (data, column) => {
    var type = entryType.find(type => type.entry_type === column);
    if (!type) {
      return '-';
    }
    var entry = type.m_entry_data.find(d => d.entry_data === data[column]);
    if (!entry) {
      return '-';
    } else {
      return entry.entry_name;
    }
  }

  const countOrder = (status) => {
    // return orderData.reduce((prev, item) => { return prev + (item[constClass.COLUMN.CLASS] === user.userClass && status.includes(item.status) ? 1 : 0) }, 0);
    return orderData.reduce((prev, item) => { return prev + (status.includes(item.status) ? 1 : 0) }, 0);
  }

  useEffect(() => {
    var intervalId;
    function fetchData() {
      refreshOrder();
      intervalId = setInterval(() => {
        refreshOrder();
      }, 5000);
    }
    refreshMaster();
    fetchData();
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshOrder, refreshMaster]);


  return (
    <div className="container">
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div className="row border mb-3 p-2">

          {/* ↓ 保留129~137 */}
          <div className="col-3">
            待ち組数：{countOrder([constClass.STATUS.REG, constClass.STATUS.PRE, constClass.STATUS.CALL])} 組
          </div>
          <div className="col-3">
            不在組数：{countData ? countData.abs_data.length : '-'} 組
          </div>
          <div className="col-12 text-right">
            {/* <span>次に発券する整理券番号：{constClass.RECEIPT_NUM[props.user_class]} 番</span> */}
            <span>次に発券する整理券番号：{newNumber} 番</span>
          </div>
        </div>
      )}
      {orderData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center">
                    順番
                  </td>
                  <td className="text-center">
                    整理券
                  </td>
                  <td className="text-center">
                    発券時刻
                  </td>
                  <td className="text-center">
                    選択肢
                  </td>
                  <td className="text-center">
                    種別
                  </td>
                  <td className="text-center">
                    窓口
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                  <td className="text-center">
                    対応
                  </td>
                  <td className="text-center">
                    完了
                  </td>
                  <td className="text-center">
                    取消
                  </td>
                </tr>
              </thead>
              <tbody>
                {orderData.map((data, idx) => (
                  // {orderData.filter(o => o[constClass.COLUMN.CLASS] === String(user.userClass)).map((data, idx) => (
                  <tr key={data.order_id}>
                    {/* 順番 */}
                    <td className="text-center align-middle">
                      {idx + 1}
                    </td>
                    {/* 整理券 */}
                    <td className="text-center align-middle">
                      {data.receipt_num ? data.receipt_num : '-'}
                    </td>
                    {/* 発券時刻 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('HH:mm') : '-'}
                    </td>
                    {/* 人数 */}
                    {/* <td className="text-center align-middle">
                      大{data[constClass.COLUMN.ADULT] ? data[constClass.COLUMN.ADULT] : '-'}　未{data[constClass.COLUMN.CHILD] ? data[constClass.COLUMN.CHILD] : '-'}
                    </td> */}
                    {/* カウンター席 */}
                    {/* <td className="text-center align-middle">
                      {constClass.COUNTER_NAME[data[constClass.COLUMN.COUNTER] ] }
                    </td> */}
                    {/* 選択肢 */}
                    <td className="text-center align-middle">
                      {/* {constClass.COUNTER_NAME[data[constClass.COLUMN.kind]]} */}
                      {renderData(data, constClass.COLUMN.kind)}
                    </td>
                    {/* 種別 */}
                    <td className="text-center align-middle">
                      {data.line_id === constClass.PAPER_ORDER ? '紙' : 'LINE'}
                    </td>
                    {/* 窓口 */}
                    <td className="text-center align-middle">
                      {data.user_short ? data.user_short : '-'}
                    </td>
                    {/* 処理 */} 
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.PRE, constClass.STATUS_NAME.PRE)}
                      {renderButton(data, constClass.STATUS.CALL, constClass.STATUS_NAME.CALL)}
                      {renderButton(data, constClass.STATUS.ABS, constClass.STATUS_NAME.ABS, [constClass.STATUS.AABS])}
                    </td>
                    {/* 対応 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.WIP, constClass.STATUS_NAME.WIP)}
                    </td>
                    {/* 完了 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.FIN, constClass.STATUS_NAME.FIN)}
                    </td>
                    {/* 取消 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.CCL, constClass.STATUS_NAME.CCL, [constClass.STATUS.ACCL, constClass.STATUS.UCCL])}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardOrder