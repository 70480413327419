import React, { useState } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import { CSVLink } from "react-csv";

const HistoryDL = (props) => {
  const [lockData, setLockData] = useState(false);
  const [historyData, setHistoryData] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState(null);
  const [csvDetailData, setCsvDetailData] = useState(null);

  const HistorySearch = async () => {
    setLockData(true);
    const reg_params = {
      "operator": "and",
      "where": [
        {
          "operator": "gte",
          "attr": "receipt_date",
          "val": moment(startDate).format('YYYY-MM-DD')
        },
        {
          "operator": "lte",
          "attr": "receipt_date",
          "val": moment(endDate).format('YYYY-MM-DD')
        }
      ]
    };

    // DB取得
    const history_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order-history/search/detail/`, reg_params)).data;
    setHistoryData(history_data);
    CreateCsvData(history_data);
    CreateCsvDetatilData(history_data);
    setLockData(false);
  }

  // ChangeCell
  const timeCellchange = (key) => (event) => {
    // 開始日
    if (event.target.name === constClass.TIME_NAME.RECEIPT_START) {
      setStartDate(event.target.value);

      // 終了日
    } else if (event.target.name === constClass.TIME_NAME.RECEIPT_END) {
      setEndDate(event.target.value);
    }
  }

  // CSVset（履歴データ）
  const CreateCsvData = (data) => {
    var list = [];
    var head = [];
    head.push({ label: "オーダーID", key: "order_id" });
    head.push({ label: "店舗ID", key: "shop_id" });
    head.push({ label: "店舗名", key: "shop_name" });
    head.push({ label: "受付日", key: "receipt_date" });
    head.push({ label: "LINEID", key: "line_id" });
    head.push({ label: "受付番号", key: "receipt_num" });
    head.push({ label: "ステータス", key: "status" });
    head.push({ label: "担当ユーザID", key: "user_id" });
    head.push({ label: "担当ユーザ名", key: "user_name" });
    head.push({ label: "データ1", key: "data1" });
    head.push({ label: "データ2", key: "data2" });
    head.push({ label: "データ3", key: "data3" });
    head.push({ label: "データ4", key: "data4" });
    head.push({ label: "作成者", key: "ins_name" });
    head.push({ label: "作成日時", key: "ins_date" });
    head.push({ label: "更新者", key: "upd_name" });
    head.push({ label: "更新日時", key: "upd_date" });
    data.forEach(p => {
      var history = {
        order_id: p.order_id,
        shop_id: p.shop_id,
        shop_name: p.shop_name,
        receipt_date: moment(p.receipt_date).format("YYYY/MM/DD"),
        line_id: p.line_id,
        receipt_num: p.receipt_num,
        status: constClass.STATUS_NAME[p.status],
        user_id: p.user_id,
        user_name: p.user_name,
        data1: p.data1,
        data2: p.data2,
        data3: p.data3,
        data4: p.data4,
        ins_name: p.ins_name,
        ins_date: moment(p.ins_date).format("YYYY/MM/DD HH:mm:ss"),
        upd_name: p.upd_name,
        upd_date: moment(p.upd_date).format("YYYY/MM/DD HH:mm:ss"),
      };

      list.push(history);
    });
    setCsvData({ header: head, list: list });
  }

  // CSVset（履歴詳細データ）
  const CreateCsvDetatilData = (data) => {
    var list = [];
    var head = [];
    head.push({ label: "オーダーID", key: "order_id" });
    head.push({ label: "履歴番号", key: "history_no" });
    head.push({ label: "ステータス", key: "status" });
    head.push({ label: "作成者", key: "ins_name" });
    head.push({ label: "作成日時", key: "ins_date" });
    head.push({ label: "更新者", key: "upd_name" });
    head.push({ label: "更新日時", key: "upd_date" });
    data.forEach(p => {
      p.t_order_status_history.forEach(q => {
        var historydetail = {
          order_id: q.order_id,
          history_no: q.history_no,
          status: q.status,
          ins_name: q.ins_name,
          ins_date: moment(q.ins_date).format("YYYY/MM/DD HH:mm:ss"),
          upd_name: q.upd_name,
          upd_date: moment(q.upd_date).format("YYYY/MM/DD HH:mm:ss"),
        };
        list.push(historydetail);
      });
    });
    setCsvDetailData({ header: head, list: list });
  }

  // 検索ボタン
  const searchButton = () => {
    return (
      <button type="button"
        disabled={lockData || !startDate || !endDate}
        className={`btn btn-primary mx-5`}
        onClick={() => HistorySearch()}>
        検索
      </button>
    )
  }

  // 初期実行トリガー
  // useEffect(() => {
  //   async function fetchData() {
  //     await refreshOrder();
  //   }
  //   return fetchData();
  // }, []);


  return (
    <div className="container">

      {/* 開始日・終了日入力フォーム */}
      <table className="table" height="1">
        <tbody>
          <tr>
            <td className="col-3 my-sm-3">
              <div className="col-sm-12 my-sm-4 csv-input">
                ＜開始日＞
                <input type="date" className="form-control" id={"receipt_date"} name={constClass.TIME_NAME.RECEIPT_START} value={moment(startDate).format('YYYY-MM-DD')} onChange={timeCellchange("receipt_date")}></input>
              </div>
            </td>
            <td className="col-sm-1 my-sm-3">
              <div className="my-sm-5 d-flex align-items-center justify-content-center">
                ～
              </div>
            </td>
            <td className="col-3 my-sm-3">
              <div className="col-sm-12 my-sm-4">
                ＜終了日＞
                <input type="date" className="form-control" id={"receipt_date"} name={constClass.TIME_NAME.RECEIPT_END} value={moment(endDate).format('YYYY-MM-DD')} onChange={timeCellchange("receipt_date")}></input>
              </div>
            </td>
            {/* 検索ボタン */}
            <td className="col-sm-5 my-sm-3">
              <div className="col-sm-12 my-sm-5">
                {searchButton()}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* 件数出力 */}
      <div className="row mb-3 p-0">
        <div className="col-12 p-0 d-flex align-items-center justify-content-center ">
          <table className="table table-bordered table-striped w-50">
            <thead className={`table-${constClass.COLOR.BUS}`}>
              <tr>
                <td className="text-center">
                  検索件数
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {historyData ? historyData.length : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="btn-toolbar">
        <div className="btn-group w-50">
          <div className="col-4">
            {csvData !== null && (
              <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list} headers={csvData.header} filename={"履歴データ.csv"}>
                履歴データ出力
              </CSVLink>
            )}
          </div>
          <div className="col-4">
            {csvDetailData !== null && (
              <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvDetailData.list} headers={csvDetailData.header} filename={"履歴詳細データ.csv"}>
                履歴詳細データ出力
              </CSVLink>
            )}
          </div>
        </div>
      </div>




    </div>
  )
}

export default HistoryDL