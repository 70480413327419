import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import util from 'util';

const UserOnly = (props) => {
  // static contextTypes = {
  //   router: PropTypes.object.isRequired
  // }
  const { user } = props;
  const history = useHistory();

  useEffect(() => {
    if (!user.isLoggedIn) {
      history.replace(`${props.match.path}login`);
    }
  }, [user, history, props.match.path])

  return <div>{props.children}</div>;
}

UserOnly.propTypes = {
  children: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

// function select({ auth }) {
//   return { auth };
// }

export default UserOnly;