import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
import Setting from '../Components/Master/Setting';
import Choices from '../Components/Master/Choices';
import BackyardUser from '../Components/Master/BackyardUser';
import BackyardUserEdit from '../Components/Master/BackyardUserEdit';
import { ToastProvider } from 'react-toast-notifications';
import BackyardOrderFinTab from '../Components/BackyardOrder/BackyardOrderFinTab';
import constClass from '../Constants/Constants';
import HistoryDL from '../Components/BackyardOrder/HistoryDL';
import Calendar from '../Components/Master/Calendar';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
};

const BackApp = (props) => {
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);

  const _userProps = { user, userActions, ...props };
  return (
    <div className='backyard-top'>
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider placement="bottom-center">
            <Route render={() =>
              <UserOnly {..._userProps} >
                <Route exact path={`${props.match.path}`} >
                  {/* <BackyardOrder {..._userProps} /> */}
                  <BackyardOrderFinTab sts={constClass.STATUS.REG} {..._userProps} />
                </Route>
                <Route path={`${props.match.path}fin`} >
                  <BackyardOrderFinTab sts={constClass.STATUS.FIN} {..._userProps} />
                </Route>
                <Route path={`${props.match.path}ccl`} >
                  <BackyardOrderFinTab sts={constClass.STATUS.CCL} {..._userProps} />
                </Route>
                <Route path={`${props.match.path}abs`} >
                  <BackyardOrderFinTab sts={constClass.STATUS.ABS} {..._userProps} />
                </Route>
                <Route path={`${props.match.path}user/edit/:id`} >
                  <BackyardUserEdit {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}user/edit`} >
                  <BackyardUserEdit {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}user`} >
                  <BackyardUser {..._userProps} />
                </Route>
                <Route path={`${props.match.path}setting`} >
                  <Setting {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}choices`} >
                  <Choices {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}historydl`} >
                  <HistoryDL {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}calendar`} >
                  <Calendar {..._userProps} />
                </Route>
              </UserOnly>
            } />
            <Route render={() =>
              <GuestOnly {..._userProps} >
                <Route path={`${props.match.path}login`} render={() =>
                  <Login {..._userProps} />
                } />
              </GuestOnly>
            } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

//<Route render={() => <NavBar {..._userProps} />} />
export default BackApp;