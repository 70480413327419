import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';

const Setting = (props) => {
  const { user } = props;
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const [settingData, setSettingData] = useState(null);

  const refreshMaster = async () => {
    const rs = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/`)).data;
    var val = rs.filter(res => res.setting_name); // setting_nameが含まれているもののみ設定可能
    val.sort((a, b) => a.setting_order < b.setting_order ? -1 : 1);
    setSettingData(val);
  }

  // セル変更イベント
  const handleChangeCell = (index, key) => (event) => {
    const _settingData = [...settingData];
    _settingData[index] = { ..._settingData[index], [key]: event.target.value, isUpdate: true };
    setSettingData(_settingData);
  }

  // DB更新：更新ボタン
  const handleUpdateClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    // 確認メッセージ
    if (!window.confirm(`設定マスタを更新します。よろしいですか？`)) {
      return;
    }
    let res;
    try {
      setLockData(true);
      if (data.isUpdate) {
        let params = {
          setting_type: data.setting_type,
          setting_val: data.setting_val,
        };
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/setting/update/${data.setting_type}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      };
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshMaster();
      }
      setLockData(false);
    }
  }

  // 更新ボタン
  const updateButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || !data.isUpdate}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        更新
      </button>
    )
  }

  // 初期実行処理トリガー
  useEffect(() => {
    async function fetchData() {
      await refreshMaster();
    }
    return fetchData();
  }, []);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {settingData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {settingData !== null && (

        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center w-25">
                    設定名称
                  </td>
                  <td className="text-center w-50">
                    設定備考
                  </td>
                  <td className="text-center w-10">
                    設定値
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {settingData.map((data, idx) => (
                  <tr key={data.setting_type}>
                    {/* 設定名称 */}
                    <td className="text-center align-middle">
                      {data.setting_name}
                    </td>
                    {/* 設定備考 */}
                    <td className="text-left align-middle">
                      {data.setting_note}
                    </td>
                    {/* 設定値 */}
                    <td className="text-right align-middle">
                      <input type="text" className="form-control text-right input-number-120" id={"setting_val_" + data.setting_type} name="setting_val[]" value={data.setting_val} onChange={handleChangeCell(idx, 'setting_val')}></input>
                    </td>
                    {/* 更新ボタン */}
                    <td className="text-center align-middle">
                      {updateButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )


}
export default Setting