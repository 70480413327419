import { createSlice } from '@reduxjs/toolkit';

// Stateの初期状態
const initialState = {
  openDispFlg: null,
  openLineFlg: null,
};

const OpenData = createSlice({
  name: "OpenData",
  initialState: initialState,
  reducers: {
    set: (state, action) => ({
      openDispFlg: action.payload.open_disp_flg,
      openLineFlg: action.payload.open_line_flg,
    }),
  }
});

// 個別でも使えるようにActionCreatorsをエクスポートしておく
export const { set } = OpenData.actions;

export default OpenData;