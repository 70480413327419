import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import Ticket from './Ticket';
import { useReactToPrint } from 'react-to-print';
import Modal from 'react-modal';
import ArrowOn from '../Images/arrow_blue.png';
import ArrowOff from '../Images/arrow_off.png';
import queryString from 'query-string';
// import Control from '../Control/Control';
// import Common from '../Common/common.js';
// console.log(searchData);

const DisplayOrder = (props) => {
  // const { openData } = props;
  const [disabled, setDisabled] = useState(false);
  const [choiceData, setChoiceData] = useState(null);
  const [order, setOrder] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [Counter, setCounter] = useState([]);
  const [setting, setSetting] = useState({});
  const { addToast } = useToasts(null);
  const componentRef = useRef();
  const [edit_Flag, seteditFlag] = useState(constClass.OFF_EDIT);
  const [unprintedFlag, setUnprintedFlag] = useState(false);
  // const modalCloseStyle = {
  //   overlay: {
  //     position: "fixed",
  //     top: 0,
  //     left: 0,
  //     width: '100%',
  //     height: '100%',
  //     backgroundColor: "rgba(0,0,0,0.5)"
  //   },
  //   content: {
  //     position: "absolute",
  //     left: '25%',
  //     right: '25%',
  //     top: '50%',
  //     bottom: 'auto',
  //     marginTop: '-12rem',
  //     borderRadius: "0rem",
  //     padding: "0px",
  //     height: "auto",
  //   }
  // };
  const modalConfirmStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '20%',
      right: '20%',
      top: '20%',
      bottom: 'auto',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto",
      maxHeight: "60%",
      width: "60%",
      overflowX: "hidden",
      overflowY: "scroll",
    }
  };

  // 初期実行処理
  useEffect(() => {
    async function fetchData() {
      await refreshOrder();
      var qs = queryString.parse(props.location.search);
      setUnprintedFlag(qs.unprinted ? true : false);
    }
    return fetchData();
  }, [props.location.search]);
 
  const printTicket = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {
      size: 60mm 160mm;
      margin: 0mm;
    }`,
  });

  // DB取得
  const refreshOrder = async () => {
    try {
      const entry_type = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/data/`)).data; //入力項目取得
      entry_type.sort((a, b) => a.entry_seq < b.entry_seq ? -1 : 1); //昇順ソート
      setChoiceData(entry_type);
      seteditFlag(constClass.OFF_EDIT);
      const setting = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`)).data; //設定マスタ取得
      setSetting(setting);
    } catch (err) {
      window.alert(err.message);
    }
  }

  // DB処理
  const submit = async () => {
    setDisabled(true);
    setConfirm(false);
    var data = {
      shop_id: constClass.SHOP_ID,
      line_id: constClass.PAPER_ORDER,
      data1: Counter.entry_data,
    };

    try {
      const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data)).data;
      addToast(`整理券を発行しました`, { appearance: 'success', autoDismiss: true })
      // 整理券発行処理
      setOrder(my_order.order);
      if (!unprintedFlag) {
        printTicket();
      }
      setDisabled(false);
    } catch (err) {
      addToast(`エラーが発生しました。${err.response.status}:${err.response.data}`, { appearance: 'error', autoDismiss: true });
    } finally {
      setDisabled(false);
      await refreshOrder();
    }
  }

  // 用件選択ボタン
  const renderRequest = (data) => {
    return (
      <div className="row mx-0 bg-white px-3 pt-3 pb-4">
        <div className="col text-center bg-lightwhite py-1 pr-1 pl-0 mr-1 rounded-right">
          <button
            disabled={disabled}
            className={`btn ${Counter === data ? "btn-blue-active" : "btn-blue-enable"} w-100 h-100 py-2 px-0`}
            onClick={() => { setCounter(data); seteditFlag(constClass.ON_EDIT); }}>
            <h2 className="font-weight-bold mb-0">{data.entry_name}</h2>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="container m-0 p-0 mw-100">
      <div className="row d-none"><div className="col-12">{util.inspect(choiceData)}</div></div>
      {choiceData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      <div className="d-none">
        <Ticket order={order} setting={setting} entry_type={choiceData} ref={componentRef} />
      </div>
      {choiceData !== null && (
        <div className="row my-0 mx-0 px-0">
          <div className="col-12 px-0">
            <div>
              {/*用件選択*/}
              <div className="col section-dark">
                <h3 className="mb-0">ご用件をお選びください</h3>
              </div>
              {choiceData.filter(d => d.disp_flag === constClass.DISP_FLAG.DISP_ON).map(data =>
                <div key={data.entry_seq}>
                  {renderRequest(data)}
                </div>
              )}

              {/*発券ボタン*/}
              {/*<div className="row mx-0 bg-white px-3 pt-3 pb-4">*/}
              <footer className="footer text-center">
                <div className="container m-0 p-0 mw-100">
                  <div className="row mx-0">
                    <div className="col text-center p-0">
                      <div className="row mx-0">
                        <div className="col text-center p-0">
                          <button
                            disabled={disabled || edit_Flag === constClass.OFF_EDIT}
                            className="btn-lg btn-blue-submit w-100 rounded p-env-bottom"
                            onClick={() => { setConfirm(true) }}>
                            <h1 className="mb-0 align-middle d-inline-block mx-2">発券 </h1>
                            <img className="arrow-lg align-middle d-inline-block" src={edit_Flag === constClass.OFF_EDIT || disabled ? ArrowOff : ArrowOn} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      )
      }

      {/* 内容確認（ポップアップ） */}
      <Modal isOpen={confirm} style={modalConfirmStyle}>
        <footer className="bg-light dti">
          <div className="row mt-1 mx-0">
            <div className="col text-center">
              <h2 className="mb-0">{`【${Counter.entry_name}】`}</h2>
            </div>
          </div>
          <div className="row mt-3 mx-0">
            <div className="col text-center">
              <h2 className="mb-0">{`で整理券を発行します`}</h2>
            </div>
          </div>
          <div className="row my-3 mx-0">
            <div className="col">
              <button
                disabled={disabled}
                className={`btn btn-blue-enable w-100 py-2`}
                onClick={() => { setConfirm(false) }}>
                <h2 className="font-weight-bold mb-0">キャンセル</h2>
              </button>
            </div>
            <div className="col">
              <button
                disabled={disabled}
                className={`btn btn-blue-enable w-100 py-2`}
                onClick={() => { submit() }}>
                <h2 className="font-weight-bold mb-0">発行</h2>
              </button>
            </div>
          </div>
        </footer>
      </Modal>
    </div >
  )
}

export default DisplayOrder;