import { combineReducers, configureStore } from '@reduxjs/toolkit';

import OpenData from '../Slices/OpenData';

const rootReducer = combineReducers({
  OpenData: OpenData.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;