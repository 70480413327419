import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import {useUnmountRef, useSafeState} from '../Common/SafeState';

// 窓口管理画面（発券画面以外）
const BackyardOrderFin = (props) => {
  const { user } = props;
  const unmountRef = useUnmountRef();
  const [orderData, setOrderData] = useSafeState(unmountRef, null);
  const [lockData, setLockData] = useSafeState(unmountRef, false);
  const { addToast } = useToasts();
  const [entryType, setEntryType] = useSafeState(unmountRef, []);
  const [tabData, setTabData] = useSafeState(unmountRef, null);

  const refreshOrder = useCallback(async () => {
    var reg_params = {};
    // if (props.statusIdx === 0) {
    //   reg_params = {
    //     "operator": "or",
    //     "where": [
    //       {
    //         "status": constClass.STATUS.FIN
    //       },
    //     ]
    //   }
    // } else 
    if (props.statusIdx === 1) {
      reg_params = {
        "operator": "or",
        "where": [
          {
            "status": constClass.STATUS.ABS
          },

          {
            "status": constClass.STATUS.AABS
          },

        ]
      }
      setTabData(constClass.STATUS.ABS);

    } else if (props.statusIdx === 2) {
      reg_params = {
        "operator": "or",
        "where": [
          {
            "status": constClass.STATUS.FIN
          },
        ]
      }
      setTabData(constClass.STATUS.FIN);

    } else if (props.statusIdx === 3) {
      reg_params = {
        "operator": "or",
        "where": [
          {
            "status": constClass.STATUS.CCL
          },
          {
            "status": constClass.STATUS.ACCL
          },
          {
            "status": constClass.STATUS.UCCL
          },
        ]
      }
      setTabData(constClass.STATUS.CCL);
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params)).data;
    data.sort((a, b) => a.order_id - b.order_id);
    setOrderData(data);
  }, [props.statusIdx, setTabData, setOrderData]);

  const refreshMaster = useCallback(async () => {
    const entry_type = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/entry_type/`)).data; //入力項目取得
    setEntryType(entry_type);
  }, [setEntryType]);

  // タブと同じステータスを押下した場合→発券タブに移動
  const ABSclick = async (id, status, R_num) => {
    if (status === tabData) {
      // 確認メッセージ
      if (!window.confirm(`[整理券番号:${R_num}]発券画面に移動しますか？`)) {
        return;
      }
      const params = {
        status: constClass.STATUS.PRE,
        user_id: user.userId,
      };
      try {
        setLockData(true);
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${id}`, params);
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        await refreshOrder();
        setLockData(false);
      }
    }
    return;
  }

  //　ステータス変更
  const handleStatusButtonClick = async (order_id, status) => {
    if (orderData.find(o => o.order_id === order_id).status === status) {
      return;
    }
    // var call = orderData.find(o => o.user_id === user.userId && [constClass.STATUS.CALL].includes(o.status));
    // if (status === constClass.STATUS.CALL && call) {
    //   addToast(`対応中の${call.receipt_num}番を完了してください。`, { appearance: 'error', autoDismiss: true });
    //   return;
    // } else {
    //   removeAllToasts();
    // }
    // const params = {
    //   status,
    //   user_id: [constClass.STATUS.CALL, constClass.STATUS.FIN, constClass.STATUS.CCL].includes(status) ? user.userId : null,
    // };

    const params = {
      status,
      user_id: user.userId,
      unnotified: true, // fin画面からのステータス更新は通知を送信しない
    };
    try {
      setLockData(true);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${order_id}`, params);
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      await refreshOrder();
      setLockData(false);
    }
  }

  //各種処理ボタン
  const renderButton = (data, status, statusName, additionalStatus = []) => {
    additionalStatus.push(status);
    statusName = [constClass.STATUS_NAME.CALL, constClass.STATUS_NAME.PRE, constClass.STATUS_NAME.WIP,].includes(statusName) && additionalStatus.includes(data.status) ? statusName + '中' : statusName;

    return (
      <button type="button"
        disabled={lockData || user.userClass === constClass.CLASS.OTHER || user.userClass === constClass.CLASS.CHECK}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
        onClick={() => { ABSclick(data.order_id, status, data.receipt_num); handleStatusButtonClick(data.order_id, status) }}>
        {statusName}
      </button>
    )
  }

  const renderData = (data, column) => {
    var type = entryType.find(type => type.entry_type === column);
    if (!type) {
      return '-';
    }
    var entry = type.m_entry_data.find(d => d.entry_data === data[column]);
    if (!entry) {
      return '-';
    } else {
      return entry.entry_name;
    }
  }

  useEffect(() => {
    var intervalId;
    function fetchData() {
      refreshOrder();
      intervalId = setInterval(() => {
        refreshOrder();
      }, 5000);
    }
    refreshMaster();
    fetchData();
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshOrder, refreshMaster]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    順番
                  </td>
                  <td className="text-center">
                    整理券
                  </td>
                  <td className="text-center">
                    発券時刻
                  </td>
                  <td className="text-center">
                    選択肢
                  </td>
                  <td className="text-center">
                    種別
                  </td>
                  <td className="text-center">
                    窓口
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                  <td className="text-center">
                    対応
                  </td>
                  <td className="text-center">
                    完了
                  </td>
                  <td className="text-center">
                    取消
                  </td>
                </tr>
              </thead>
              <tbody>
                {orderData.map((data, idx) => (
                  // {orderData.filter(o => o[constClass.COLUMN.CLASS] === String(user.userClass)).map((data, idx) => (
                  <tr key={data.order_id}>
                    {/* 順番 */}
                    <td className="text-center align-middle">
                      {idx + 1}
                    </td>
                    {/* 整理券 */}
                    <td className="text-center align-middle">
                      {data.receipt_num ? data.receipt_num : '-'}
                    </td>
                    {/* 発見時刻 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('HH:mm') : '-'}
                    </td>
                    {/* 選択肢 */}
                    <td className="text-center align-middle">
                      {renderData(data, constClass.COLUMN.kind)}
                    </td>
                    {/* 種別 */}
                    <td className="text-center align-middle">
                      {data.line_id === constClass.PAPER_ORDER ? '紙' : 'LINE'}
                    </td>
                    {/* 窓口 */}
                    <td className="text-center align-middle">
                      {data.user_short ? data.user_short : '-'}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.PRE, constClass.STATUS_NAME.PRE)}
                      {renderButton(data, constClass.STATUS.CALL, constClass.STATUS_NAME.CALL)}
                      {renderButton(data, constClass.STATUS.ABS, constClass.STATUS_NAME.ABS, [constClass.STATUS.AABS])}
                    </td>
                    {/* 対応 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.WIP, constClass.STATUS_NAME.WIP)}
                    </td>
                    {/* 完了 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.FIN, constClass.STATUS_NAME.FIN)}
                    </td>
                    {/* 取消 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.CCL, constClass.STATUS_NAME.CCL, [constClass.STATUS.ACCL, constClass.STATUS.UCCL])}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardOrderFin;