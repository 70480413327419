import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';

const Choices = (props) => {
  const { addToast } = useToasts();
  const [lockData, setLockData] = useState(false);
  const [choicesData, setChoicesData] = useState(null);
  const [deleteData, setDeleteData] = useState([]);
  const [maxId, setMaxId] = useState(null);
  const [editFlag, setEditFlag] = useState(constClass.OFF_EDIT);

  const refreshMaster = async () => {
    const rs = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/choices/search/`)).data;
    const val = rs.map(value => ({ id: value.entry_seq, ...value })) // 配列にidカラム追加作成
    val.sort((a, b) => a.entry_seq < b.entry_seq ? -1 : 1);
    setChoicesData(val);
    setMaxId(Math.max(...val.map((p) => p.id)));
  }

  // セル変更イベント
  const handleChangeCell = (index, key) => (event) => {
    const target = event.target;
    const _choicesData = [...choicesData]
    const value = target.type === "checkbox" ? (target.checked ? constClass.DISP_FLAG.DISP_ON : constClass.DISP_FLAG.DISP_OFF) : target.value;
    _choicesData[index] = { ..._choicesData[index], [key]: value, isupdate: true }
    setChoicesData(_choicesData)
    setEditFlag(constClass.ON_EDIT)
  }

  // 更新ボタン：登録/更新/削除処理
  const handleUpdateClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 確認メッセージ
    if (!window.confirm(`選択肢マスタを更新します。よろしいですか？`)) {
      return;
    }
    setLockData(true);

    // 登録・更新・削除分岐処理
    var array = data;　// 新規・更新Data
    var del_array = deleteData;　// 削除Data
    var errorFlag = false; // エラーFlag
    await Promise.all(array.map(async function (value, index, array) {

      let res;
      if (!value.entry_type) { // 条件→新規作成(登録)行のみ
        try {
          let newparams = {
            entry_type: constClass.CLASS_CHOICES.TYPE,
            entry_seq: value.entry_seq,
            input_type: constClass.CLASS_CHOICES.IN_TYPE,
            entry_name: value.entry_name,
            entry_data: value.entry_data,
            param_name: constClass.CLASS_CHOICES.PARAM,
            disp_flag: value.disp_flag ? constClass.DISP_FLAG.DISP_ON : constClass.DISP_FLAG.DISP_OFF
          };
          setLockData(true);
          res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/choices/insert`, newparams, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            }
          });
        } catch (err) {
          if (err.response.data !== null) {
            addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
            errorFlag = true;
          } else {
            addToast(err.response, { appearance: 'error', autoDismiss: true });
            errorFlag = true;
          }
        } finally {
          if (res && res.data.error) {
            addToast(res.data.message, { appearance: 'error', autoDismiss: true });
            errorFlag = true;
          }
        }
      };
      if (value.isupdate && value.entry_type) { // 条件→更新行のみ
        try {
          let updparams = {
            entry_seq: value.entry_seq,
            entry_name: value.entry_name,
            entry_data: value.entry_data,
            disp_flag: value.disp_flag,
          };
          res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/choices/update/${value.id}`, updparams, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            }
          });
        } catch (err) {
          if (err.response.data !== null) {
            addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
            errorFlag = true;
          } else {
            addToast(err.response, { appearance: 'error', autoDismiss: true });
            errorFlag = true;
          }
        } finally {
          if (res && res.data.error) {
            addToast(res.data.message, { appearance: 'error', autoDismiss: true });
            errorFlag = true;
          }
        }
      }
    }));

    // 削除処理
    await Promise.all(del_array.map(async function (value, index, array) {
      try {
        let delparams = {
          entry_type: value.entry_type,
          entry_seq: value.entry_seq,
        };
        setLockData(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/choices/delete`, delparams, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
          errorFlag = true;
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
          errorFlag = true;
        }
      }
    }));
    if (errorFlag === false) {
      addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      await refreshMaster();
      setEditFlag(constClass.OFF_EDIT);
    }
    setLockData(false);
  }

  // 削除ボタン：処理
  const handleDeleteClick = async (data) => {
    // 確認メッセージ
    if (!window.confirm(`選択肢マスタ[${data.entry_seq}:${data.entry_name}]を削除します。よろしいですか？`)) {
      return;
    }

    // 削除対象Dataをpush
    if (data.entry_type) { // 既存レコードの場合
      let val = Array.from(deleteData);
      val.push({
        entry_type: data.entry_type,
        entry_seq: data.entry_seq,
      });
      setDeleteData(val);
      setEditFlag(constClass.ON_EDIT);
    }
    // 対象レコードを削除
    let deldata = Array.from(choicesData);
    let idx = deldata.findIndex(o => o.id === data.id);
    deldata.splice(idx, 1);
    setChoicesData(deldata);
  }

  // 新規登録ボタン：処理
  const handleRegisterNewClick = () => {
    // 新規作成行にid付与
    var numId = maxId;
    numId = Number(numId) + 1;
    setMaxId(numId);
    let addData = Array.from(choicesData);
    addData.push({
      entry_seq: "",
      entry_name: "",
      entry_data: "",
      disp_flag: false,
      id: numId,
    });
    setChoicesData(addData);
    setEditFlag(constClass.ON_EDIT);
  }

  // 更新ボタン
  const updateButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || editFlag === constClass.OFF_EDIT}
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        更新
      </button>
    )
  }

  // 削除ボタン
  const deleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-5`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  // 初期実行処理トリガー
  useEffect(() => {
    refreshMaster();
  }, []);

  return (
    <div className="container">

      {/* 新規登録ボタン */}
      <div className="col-sm-13 mb-3 text-right">
        <button className="btn btn-dark" onClick={handleRegisterNewClick}>選択肢新規作成</button>
      </div>

      {choicesData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {choicesData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped" id="choicesTable">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center">
                    入力順位
                  </td>
                  <td className="text-center">
                    入力名
                  </td>
                  <td className="text-center">
                    入力内容
                  </td>
                  <td className="text-center">
                    画面表示
                  </td>
                  <td className="text-center">
                    削除ボタン
                  </td>
                </tr>
              </thead>
              <tbody>
                {choicesData.map((data, idx) => (
                  <tr key={data.id}>
                    {/* 入力順位 */}
                    <td className="text-center align-middle col-2">
                      <input type="text" className="form-control text-center" id={"entry_seq" + data.entry_seq} name="entry_seq[]" value={data.entry_seq} onChange={handleChangeCell(idx, 'entry_seq')}></input>
                    </td>
                    {/* 入力名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control text-center" id={"entry_name" + data.entry_name} name="entry_name[]" value={data.entry_name} onChange={handleChangeCell(idx, 'entry_name')}></input>
                    </td>
                    {/* 入力内容 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control text-center" id={"entry_data" + data.entry_data} name="entry_data[]" value={data.entry_data} onChange={handleChangeCell(idx, 'entry_data')}></input>
                    </td>
                    {/* 表示項目 */}
                    <td className="text-center align-middle">
                      <input type="checkbox" className="form-control" id={"disp_flag" + data.disp_flag} name={'disp_flag[]'} checked={data.disp_flag === constClass.DISP_FLAG.DISP_ON} value={data.disp_flag} onChange={handleChangeCell(idx, 'disp_flag')}></input>

                    </td>
                    {/* 削除ボタン */}
                    <td className="text-center align-middle">
                      {deleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
            {/* 更新ボタン */}
            <div className="col-sm-7 my-sm-5 text-right">
              {updateButton(choicesData)}
            </div>
          </div>
        </div>
      )}
    </div>
  )

}
export default Choices