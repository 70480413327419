import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import 'moment/locale/ja'
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Calendar = (props) => {
  const [calendarData, setCalendarData] = useState(null);
  const { addToast } = useToasts();
  const [lockData, setLockData] = useState(false);
  const [setting, setSetting] = useState(null);
  const [calendarStart, setCalendarStart] = useState('');
  const [calendarEnd, setCalendarEnd] = useState('');
  const [calendarLineStart, setCalendarLineStart] = useState('');
  const [calendarLineEnd, setCalendarLineEnd] = useState('');
  const [date, setDate] = useState(null);

  moment.locale('ja');

  const refreshMaster = useCallback(async () => {
    const rs = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/`)).data;
    setSetting(rs);
  }, []);

  // ChangeCalendar
  const handleCalendarChange = useCallback((value, data) => {
    clearData();
    setDate(value);
    const cal = data.find(c => c.calendar_date === moment(value).format('YYYYMMDD'));
    if (cal) {
      setCalendarStart(cal.calendar_start);
      setCalendarEnd(cal.calendar_end);
      setCalendarLineStart(cal.calendar_line_start);
      setCalendarLineEnd(cal.calendar_line_end);
    }
  }, []);

  const refreshData = useCallback(async (date = {}) => {
    if (!date.activeStartDate) {
      date.activeStartDate = moment().startOf('month');
    }
    const params = {
      "operator": "and",
      "where": [
        {
          "operator": "gte",
          "attr": "calendar_date",
          "val": moment(date.activeStartDate).format('YYYYMMDD')
        },
        {
          "operator": "lt",
          "attr": "calendar_date",
          "val": moment(date.activeStartDate).add(1, "months").format('YYYYMMDD')
        },
      ]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/search/`, params)).data;
    data.sort((a, b) => Number(a.calendar_date) - Number(b.calendar_date));
    setCalendarData(data);
    // 移動後の月の日付をクリックされている場合はクリック処理を実行
    if (date.value && date.activeStartDate.getMonth() === date.value.getMonth()) {
      handleCalendarChange(date.value, data);
    } else {
      clearData();
    }
  }, [handleCalendarChange]);

  // 平日or休日
  const handleDayClick = (day) => {
    if (day === 'DAY') {
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_DEFAULT_START)) {
        setCalendarStart(setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_DEFAULT_START).setting_val);
      }
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_DEFAULT_END)) {
        setCalendarEnd(setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_DEFAULT_END).setting_val);
      }
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_DEFAULT_START)) {
        setCalendarLineStart(setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_DEFAULT_START).setting_val);
      }
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_DEFAULT_END)) {
        setCalendarLineEnd(setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_DEFAULT_END).setting_val);
      }
    }
    else if (day === 'HOLIDAY') {
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_HOLIDAY_START)) {
        setCalendarStart(setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_HOLIDAY_START).setting_val);
      }
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_HOLIDAY_END)) {
        setCalendarEnd(setting.find(s => s.setting_type === constClass.SETTING.CAL_DISP_HOLIDAY_END).setting_val);
      }
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_HOLIDAY_START)) {
        setCalendarLineStart(setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_HOLIDAY_START).setting_val);
      }
      if (setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_HOLIDAY_END)) {
        setCalendarLineEnd(setting.find(s => s.setting_type === constClass.SETTING.CAL_LINE_HOLIDAY_END).setting_val);
      }
    }
  }

  // DB更新：更新ボタン
  const handleUpdateClick = async () => {
    const jwt = localStorage.getItem('jwt');
    // 確認メッセージ
    if (!window.confirm(`カレンダーマスタを更新します。よろしいですか？`)) {
      return;
    }
    try {
      setLockData(true);
      const params = {
        calendar_date: moment(date).format('YYYYMMDD'),
        calendar_start: calendarStart,
        calendar_end: calendarEnd,
        calendar_line_start: calendarLineStart,
        calendar_line_end: calendarLineEnd
      }

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      await refreshData({ activeStartDate: moment(date).startOf('month') });
      setLockData(false);
    }
  }

  // ChangeCalendar：編集ボタン
  const handleEditClick = (value) => {
    handleCalendarChange(moment(value, 'YYYYMMDD').toDate(), calendarData);
  }

  // DB削除：削除ボタン
  const handleDeleteClick = async () => {
    const calendar_date = moment(date).format('YYYYMMDD');
    if (!window.confirm(`カレンダーマスタ[${calendar_date}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      calendar_date
    };

    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/delete`, params);

    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data && res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData({ activeStartDate: moment(date).startOf('month') });
      }
      setLockData(false);
    }
  }

  // カレンダークリア
  const clearData = () => {
    setDate(null);
    setCalendarStart('');
    setCalendarEnd('');
    setCalendarLineStart('');
    setCalendarLineEnd('');
  }

  const getTileContent = ({ date, view }) => {
    // calendarDataが未設定のときは何もしない
    if (!calendarData) {
      return null;
    }
    // 月表示のときのみ
    if (view !== 'month') {
      return null;
    }
    const cal = calendarData.find(c => c.calendar_date === moment(date).format('YYYYMMDD'));
    return (
      <p className="mb-0">
        {(cal) ? '○' : '　'}
      </p>
    );
  }

  // 初期実行処理トリガー
  useEffect(() => {
    refreshData();
    refreshMaster();
  }, [refreshData, refreshMaster]);

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-6 text-center">
          <ReactCalendar
            onChange={(v) => handleCalendarChange(v, calendarData)}
            value={date}
            formatDay={(locale, date) => moment(date).format('D')}
            onActiveStartDateChange={(date) => refreshData(date)}
            tileContent={getTileContent}
            calendarType="US"
            locale="ja-JP" />
        </div>
        <div className="col-6">
          <div className="row">
            <label htmlFor="calendar_date" className="col-sm-3 col-form-label">日付</label>
            <div className="col-sm-9">
              <input type="text" readOnly className="form-control-plaintext" id={"calendar_date"} name="calendar_date" value={date ? moment(date).format('YYYY年M月D日(ddd)') : '日付を選択してください'}></input>
            </div>
          </div>
          <div className="row">
            <table className="table table-bordered">
              <thead className="table-info text-center">
                <tr>
                  <td></td>
                  <td>営業開始</td>
                  <td>営業終了</td>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr key='key1'>
                  <th>LINE</th>
                  <td>
                    <input type="text" className="form-control" id={"calendar_line_start"} name="calendar_line_start" value={calendarLineStart} onChange={(e) => setCalendarLineStart(e.target.value)}></input>
                  </td>
                  <td>
                    <input type="text" className="form-control" id={"calendar_line_end"} name="calendar_line_end" value={calendarLineEnd} onChange={(e) => setCalendarLineEnd(e.target.value)}></input>
                  </td>
                </tr>
                <tr key='key2'>
                  <th>紙</th>
                  <td>
                    <input type="text" className="form-control" id={"calendar_start"} name="calendar_start" value={calendarStart} onChange={(e) => setCalendarStart(e.target.value)}></input>
                  </td>
                  <td>
                    <input type="text" className="form-control" id={"calendar_end"} name="calendar_end" value={calendarEnd} onChange={(e) => setCalendarEnd(e.target.value)}></input>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="col-12">※10:00の場合、1000と入力します。</div>
            {/* 平日ボタン */}
            <div className="col-3 my-1 text-center">
              <button type="button"
                disabled={lockData || !date}
                className={`btn btn-primary`}
                onClick={() => handleDayClick('DAY')}>
                平日
              </button>
            </div>
            {/* 休日ボタン */}
            <div className="col-3 my-1 text-center">
              <button type="button"
                disabled={lockData || !date}
                className={`btn btn-primary`}
                onClick={() => handleDayClick('HOLIDAY')}>
                休日
              </button>
            </div>
            {/* 更新ボタン */}
            <div className="col-3 my-1 text-center">
              <button type="button"
                disabled={lockData || !date || !calendarStart || !calendarEnd || !calendarLineStart || !calendarLineEnd}
                className={`btn btn-primary`}
                onClick={() => handleUpdateClick()}>
                更新
              </button>
            </div>
            {/* 削除ボタン */}
            <div className="col-3 my-1 text-center">
              <button type="button"
                disabled={lockData || !date || !calendarData.find(c => c.calendar_date === moment(date).format('YYYYMMDD'))}
                className={`btn btn-primary`}
                onClick={() => handleDeleteClick()}>
                削除
              </button>
            </div>
          </div>
        </div>
      </div>

      {calendarData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center">
                    日付
                  </td>
                  <td className="text-center">
                    営業開始(LINE)
                  </td>
                  <td className="text-center">
                    営業終了(LINE)
                  </td>
                  <td className="text-center">
                    営業開始(紙)
                  </td>
                  <td className="text-center">
                    営業終了(紙)
                  </td>
                  <td className="text-center">
                    編集
                  </td>
                </tr>
              </thead>
              <tbody>
                {calendarData.map((data, idx) => (
                  <tr key={data.calendar_date}>
                    {/* 日付 */}
                    <td className="text-center align-middle col-2">
                      {data.calendar_date}
                    </td>
                    {/* 営業開始(LINE) */}
                    <td className="text-center align-middle">
                      {data.calendar_line_start}
                    </td>
                    {/* 営業終了(LINE) */}
                    <td className="text-center align-middle">
                      {data.calendar_line_end}
                    </td>
                    {/* 営業開始(紙) */}
                    <td className="text-center align-middle">
                      {data.calendar_start}
                    </td>
                    {/* 営業終了(紙) */}
                    <td className="text-center align-middle">
                      {data.calendar_end}
                    </td>
                    {/* 編集 */}
                    <td className="text-center align-middle">
                      <button type="button"
                        disabled={lockData}
                        className={`btn btn-primary`}
                        onClick={() => handleEditClick(data.calendar_date)}>
                        編集
                      </button>
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Calendar