import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import util from 'util';
// import moment from 'moment';
import axios from 'axios';
import constClass from '../../Constants/Constants'

const NavBar = (props) => {
  const { user, userActions } = props;
  // const [calendar, setCalendar] = useState();
  const history = useHistory();

  const handleTitleClick = () => {
    userActions.refresh();
    history.replace(`${props.match.path}`);
  }

  const handleLogoutClick = () => {
    localStorage.removeItem('jwt');
    userActions.logout();
    history.replace(`${props.match.path}/login`);
  }

  // const handleDispCloseClick = () => {
  //   if (window.confirm(`発券を停止しますか？`)) {
  //     var data = {
  //       open_type: constClass.SETTING.OPEN_DISP_FLG,
  //       open_flg: constClass.CLOSE,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`発券を停止しました`);
  //   }
  // }

  // const handleLineCloseClick = () => {
  //   if (window.confirm(`LINEの発券を停止しますか？`)) {
  //     var data = {
  //       open_type: constClass.SETTING.OPEN_LINE_FLG,
  //       open_flg: constClass.CLOSE,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`LINEの発券を停止しました`);
  //   }
  // }

  // const handleDispOpenClick = () => {
  //   if (window.confirm(`発券を再開しますか？`)) {
  //     var data = {
  //       open_type: constClass.SETTING.OPEN_DISP_FLG,
  //       open_flg: constClass.OPEN,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`発券を再開しました`);
  //   }
  // }

  // const handleLineOpenClick = () => {
  //   if (window.confirm(`LINEの発券を再開しますか？`)) {
  //     var data = {
  //       open_type: constClass.SETTING.OPEN_LINE_FLG,
  //       open_flg: constClass.OPEN,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`LINEの発券を再開しました`);
  //   }
  // }

  // const UserManagementClick = () => {
  //   try {
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/`);
  //   } catch (err) {
  //     this.setState({ message: err });
  //   }
  // }

  const handleWaitCloseClick = () => {
    if (window.confirm(`順番待ちを停止しますか？`)) {
      var data = {
        open_type: constClass.SETTING.OPEN_WAIT_FLG,
        open_flg: constClass.CLOSE,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
      window.alert(`順番待ちを停止しました`);
    }
  }

  const handleWaitOpenClick = () => {
    if (window.confirm(`順番待ちを開始しますか？`)) {
      var data = {
        open_type: constClass.SETTING.OPEN_WAIT_FLG,
        open_flg: constClass.OPEN,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
      window.alert(`順番待ちを開始しました`);
    }
  }


  useEffect(() => {
    async function fetchData() {
      // // const date = moment().format('YYYYMMDD');
      // try {
      //   // const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/calendar/${date}`)).data;
      //   // setCalendar(data);
      // } catch (err) {
      //   // setCalendar(null);
      // };
    }
    fetchData();
  }, []);

  return (
    <nav className="navbar navbar-light navbar-expand-lg  bg-light fixed-top">
      <Link className="navbar-brand" onClick={handleTitleClick} to={`${props.match.path}`}>
        西鉄旅行　天神支店　順番待ち<div className='d-none'>{util.inspect(history)}</div>
      </Link>
      {/* 
      <Link className="navbar-brand" onClick={handleTitleClick} to={`${props.match.path}`}>
        定期券順番待ち <div className='d-none'>{util.inspect(history)}</div>
      </Link> 
      */}
      {
        user.isLoggedIn &&
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      }
      {
        user.isLoggedIn &&
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={`${props.match.path}`}>窓口管理</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${process.env.REACT_APP_FRONTEND_URL}/display?unprinted=1`} target="_blank" rel="noreferrer">整理券発行</a>
            </li>
            {/* {user.userClass === constClass.CLASS.CHECK &&
              <li className="nav-item">
                <Link className="nav-link" to={`${props.match.path}checkin`}>チェックイン管理</Link>
              </li>
            } */}
            {/* {user.userClass === constClass.CLASS.CHECK && */}
            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                マスタ管理
              </div>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link className="dropdown-item" to={`${props.match.path}user`}>ユーザーマスタ</Link>
                <Link className="dropdown-item" to={`${props.match.path}setting`}>設定マスタ</Link>
                <Link className="dropdown-item" to={`${props.match.path}choices`}>選択肢マスタ</Link>
                <Link className="dropdown-item" to={`${props.match.path}calendar`}>カレンダーマスタ</Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                開始/停止設定
              </div>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                {/* <a className="dropdown-item" href="#" onClick={handleDispCloseClick}>発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleLineCloseClick}>LINE発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleDispOpenClick}>発券再開</a>
                  <a className="dropdown-item" href="#" onClick={handleLineOpenClick}>LINE発券再開</a> */}
                <button className="dropdown-item" onClick={handleWaitOpenClick}>順番待ち開始</button>
                <button className="dropdown-item" onClick={handleWaitCloseClick}>順番待ち停止</button>
                {/* <Link className="dropdown-item" to={`${props.match.path}user`}>ユーザー管理</Link> */}
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${props.match.path}HistoryDL`}>履歴データ出力</a>
            </li>
            {/* } */}
          </ul>
        </div>
      }
      {
        user.isLoggedIn &&
        <div>
          <label className="mr-2 text-primary">{user.userName}</label>
          <button className="btn btn-dark" onClick={handleLogoutClick}>ログアウト</button>
        </div>
      }
    </nav>
  );
}

export default NavBar;