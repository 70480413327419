import React, { Component } from 'react';
import Order from '../Components/Order/Order';
import queryString from 'query-string';
import constClass from '../Constants/Constants';
// import VConsole from 'vconsole';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      liff_access_token: null,
      liff_user_id: null,
      channel_id: null,
      page: null,
      open_data: null,
      my_order: null,
    };

    this.liff_init = this.liff_init.bind(this);
  }

  liff_init() {
    if (window.liff.isInClient() && window.liff.isLoggedIn()) {
      const accessToken = window.liff.getAccessToken();
      const decodedId = window.liff.getDecodedIDToken();
      this.setState({
        liff_access_token: accessToken,
        liff_user_id: decodedId.sub
      });
    } else {
      this.setState({
        liff_access_token: 'token_unusable',
        liff_user_id: 'test_user_id'
      });
    }
  }

  setPage(page) {
    this.setState({ page: page });
  }

  setOpenData(open_data) {
    this.setState({ open_data: open_data });
  }

  setMyOrder(order) {
    this.setState({ my_order: order });
  }

  async componentDidMount() {
    // if (process.env.REACT_APP_ENV !== 'pro') {
    //   var vConsole= new VConsole();
    // }
    var qs = queryString.parse(this.props.location.search);
    var liffqs = (qs["liff.state"] !== undefined ? queryString.parse(qs["liff.state"]) : null);
    this.setState({ channel_id: qs.channel, page: qs.page });
    if (process.env.REACT_APP_ENV !== 'dev') {
      if (window.liff.id === null) {
        var liffId;
        liffId = process.env.REACT_APP_LIFF_ID;
        if (liffqs !== null && liffqs.page === constClass.CANCEL) {
          liffId = process.env.REACT_APP_LIFF_ID_CANCEL;
        }
        window.liff.init({ liffId: liffId });
      }
      window.liff.ready.then(this.liff_init);
    } else {
      this.liff_init();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var qs = queryString.parse(this.props.location.search);
      this.setState({ channel_id: qs.channel, page: qs.page });
    }
  }

  render() {
    return (
        <Order
          liff_access_token={this.state.liff_access_token}
          liff_user_id={this.state.liff_user_id}
          channel_id={this.state.channel_id}
          page={this.state.page}
          setPage={(page) => { this.setPage(page); }} />
    );
  }
}

export default App;