import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { useHistory } from 'react-router-dom';

const BackyardUser = (props) => {
  const [orderData, setOrderData] = useState(null);
  const history = useHistory();
  const [searchData, setSearchData] = useState(null);

  const refreshOrder = async () => {
    const reg_params = {
      "operator": "or",
      "where": [
        {
          "status": constClass.STATUS.REG
        },
        {
          "status": constClass.STATUS.CALL
        },
        {
          "status": constClass.STATUS.WIP
        }
      ]
    }
    const rs = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params)).data;
    rs.sort((a, b) => a.order_id - b.order_id);
    setOrderData(rs);
    const val = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/search/`)).data;
    val.sort((a, b) => a.user_id > b.user_id ? 1 : -1);
    setSearchData(val);
  }

  // 画面遷移：編集ボタン
  const handleEditClick = async (user_id) => {
    if (orderData.find(o => o.user_id === user_id)) {
      return;
    }
    history.push(`${props.match.path}user/edit/${user_id}`);
  }

  // 画面遷移：新規登録ボタン
  const handleRegisterNewClick = () => {
    history.push(`${props.match.path}user/edit`);
  }

  // 初期実行処理トリガー
  useEffect(() => {
    refreshOrder();
  }, []);

  // 編集ボタン
  const editbutton = (data) => {
    return (
      < button type="button"
        disabled={orderData.find(o => o.user_id === data.user_id)}
        className={`btn btn-${orderData.find(o => o.user_id === data.user_id) ? 'secondary' : 'primary'} mx-1`}
        onClick={() => handleEditClick(data.user_id)}
      >編集
      </button>
    )
  }

  return (
    <div className="container">

      {/* 新規登録ボタン */}
      <div className="col-sm-13 mb-3 text-right">
        <button className="btn btn-dark" onClick={handleRegisterNewClick}>ユーザー新規登録</button>
      </div>

      {searchData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {searchData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center">
                    ユーザーID
                  </td>
                  <td className="text-center">
                    ユーザー名
                  </td>
                  <td className="text-center">
                    編集
                  </td>
                </tr>
              </thead>
              <tbody>
                {searchData.map((data) => (
                  <tr key={data.user_id}>
                    {/* ユーザーID */}
                    <td className="text-center align-middle">
                      {data.user_id}
                    </td>
                    {/* ユーザー名 */}
                    <td className="text-center align-middle">
                      {data.user_name}
                    </td>
                    {/* 編集 */}
                    <td className="text-center align-middle">
                      {editbutton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )


}
export default BackyardUser