import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import util from 'util';
import sound from '../Sound/Notify';
import voice from '../Sound/Voice';

const Signage = (props) => {
  const [wait_count, setWaitCount] = useState(null);
  const [call_data, setCallData] = useState(null);
  const [abs_data, setAbsData] = useState(null);
  const [highlight, setHighlight] = useState([]);
  const [count_order, setCountOrder] = useState('');
  var previous_call_data_ref = useRef(null);
  // console.log(call_data);


  const refreshOrderCount = useCallback(async () => {
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/`)).data;
    const setting = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`)).data; //設定マスタ取得
    const notify_use_flg = setting.find(s => s.setting_type === constClass.SETTING.NOTIFY_USE_FLG) ? Number(setting.find(s => s.setting_type === constClass.SETTING.NOTIFY_USE_FLG).setting_val) : 0;
    const voice_use_flg = setting.find(s => s.setting_type === constClass.SETTING.VOICE_USE_FLG) ? Number(setting.find(s => s.setting_type === constClass.SETTING.VOICE_USE_FLG).setting_val) : 0;
    const pitch = setting.find(s => s.setting_type === constClass.SETTING.VOICE_PITCH) ? Number(setting.find(s => s.setting_type === constClass.SETTING.VOICE_PITCH).setting_val) : 1;
    const rate = setting.find(s => s.setting_type === constClass.SETTING.VOICE_RATE) ? Number(setting.find(s => s.setting_type === constClass.SETTING.VOICE_RATE).setting_val) : 1;
    const previous_call_data = previous_call_data_ref.current;
    // console.log(previous_call_data);
    // sound play
    if (data.call_data && previous_call_data) {
      var highlight_data = data.call_data.map((data) => ((data.receipt_num && data.receipt_num !== previous_call_data.find(p => p.user_id === data.user_id).receipt_num) ? data.receipt_num : undefined)).filter(v => v);
      setHighlight(highlight_data);
      console.log(highlight_data);
      if (highlight_data.length > 0) {
        if(notify_use_flg) {
          sound.play().catch( error => console.log(error) );
        }
        if (voice_use_flg) {
          highlight_data.forEach(d => {
            voice.play(`お待たせしました。${d}番のかた、${data.call_data.find(c => c.receipt_num === d).user_voice}へお越しください`, pitch, rate);
          });
        }
      }
    }
    setWaitCount(data.wait_count);
    setCountOrder(data.wait_count !== null && data.wait_count.length > 0 ? data.wait_count[0].count_order : 0);

    // 呼出中(CALL)
    setCallData(data.call_data);

    // 不在(ABS)
    var check_blank = new Array(constClass.MAX_ABS - (data.abs_data ? Math.min(data.abs_data.length, constClass.MAX_ABS) : 0)).fill({});
    setAbsData(data.abs_data.slice(0, constClass.MAX_ABS).concat(check_blank));

    previous_call_data_ref.current = data.call_data;
  }, []);

  // 半角英数字を全角英数字に変換する
  const hankaku2Zenkaku = (str) => {
    return str.replace(/[A-Za-z0-9]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
  }

  useEffect(() => {
    var intervalId;
    function fetchData() {
      refreshOrderCount();
      intervalId = setInterval(() => {
        refreshOrderCount();
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
    return fetchData();
  }, [refreshOrderCount]);

  return (
    <div className="container-fluid text-center">
      <div className="row px-2 pb-2 pt-4">
        {wait_count !== null && (
          <div className="col-12">
            <h1 className="waiting">現在     {hankaku2Zenkaku(count_order)}     組待ち</h1>
          </div>
        )}
      </div>
      <div className="row p-2">
        <div className="d-none">{util.inspect(call_data)}</div>
        <div className="col-12">
          <table className="table table-call">
            <thead className="bg-call-head">
              <tr>
                <td colSpan="4">
                  <h1 className="mb-0 call-head">お呼出中の整理券番号</h1>
                </td>
              </tr>
            </thead>
            <tbody className="bg-white">
              {call_data !== null && call_data.map((data, idx) => (
                idx % 2 === 0 ? (

                  <tr key={data.user_id}>
                    <div className="bg-call-item"><td className="w-25"><h1 className="mb-0 call">{data.user_short ? hankaku2Zenkaku(data.user_short) : '-'}</h1></td></div>
                    <td className="w-25"><h1 className={`mb-0 call ${highlight.includes(data.receipt_num) ? "blink" : ""}`}>{data.receipt_num ? hankaku2Zenkaku(data.receipt_num) : '-'}</h1></td>
                    {(idx + 1) < call_data.length &&
                      <div className="bg-call-item"><td className="w-25"><h1 className="mb-0 call">{call_data[idx + 1].user_short ? hankaku2Zenkaku(call_data[idx + 1].user_short) : '-'}</h1></td></div>
                    }
                    {(idx + 1) < call_data.length &&
                      <td className="w-25"><h1 className={`mb-0 call ${highlight.includes(call_data[idx + 1].receipt_num) ? "blink" : ""}`}>{call_data[idx + 1].receipt_num ? hankaku2Zenkaku(call_data[idx + 1].receipt_num) : '-'}</h1></td>
                    }
                  </tr>
                ) : null
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row p-2">
        <div className="col-12">
          <table className="table table-absence">
            <thead className="bg-absence-head">
              <tr>
                <td colSpan="5">
                  <h1 className="mb-0 absence-head">お呼び出し済みでご不在のお客様</h1>
                </td>
              </tr>
            </thead>
            <tbody className="bg-absence">
              {abs_data !== null && abs_data.map((data, idx) => (
                idx % 5 === 0 ? (
                  <tr key={idx}>
                    <td className="w-20"><h1 className={`mb-0 absence `}>{data.receipt_num ? hankaku2Zenkaku(data.receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence `}>{abs_data[idx + 1].receipt_num ? hankaku2Zenkaku(abs_data[idx + 1].receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence `}>{abs_data[idx + 2].receipt_num ? hankaku2Zenkaku(abs_data[idx + 2].receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence `}>{abs_data[idx + 3].receipt_num ? hankaku2Zenkaku(abs_data[idx + 3].receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence `}>{abs_data[idx + 4].receipt_num ? hankaku2Zenkaku(abs_data[idx + 4].receipt_num) : "-"}</h1></td>
                  </tr>
                ) : null
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row p-2">
        <div className="col text-danger">
          <h2 className="message">上記整理券をお持ちのお客様は店舗スタッフにお声がけください</h2>
        </div>
      </div>
    </div>
  )
}

export default Signage;