var Voice = {};

if (typeof SpeechSynthesisUtterance !== "undefined") {
  // Chromeの不具合対策で一旦ボイスリストを読み込む
  Voice.voices = speechSynthesis.getVoices();
}

Voice.play = (text, pitch = 1, rate = 1) => {
  if (typeof SpeechSynthesisUtterance === "undefined") {
    return;
  }
  // 発言を作成
  const uttr = new SpeechSynthesisUtterance(text)
  // ピッチ変更
  uttr.pitch = pitch;
  // レート変更
  uttr.rate = rate;
  //「Google 日本語」のvoiceオブジェクトを取得
  const v = speechSynthesis.getVoices().find((d) => d.name === 'Google 日本語');

  // 取得できた場合のみ適用する
  if (v) {
    uttr.voice = v;
  }

  // 発言を再生 (発言キューに発言を追加)
  speechSynthesis.speak(uttr)
}

export default Voice;