import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';
import voice from '../Sound/Voice';

const BackyardUserEdit = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const [userData, setUserData] = useState(null);
  const [settingData, setSettingData] = useState(null);
  const { addToast } = useToasts();

  const refreshMaster = useCallback(async () => {
    const val = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/`)).data;
    setSettingData(val);

    if (id) {　// id有　→　編集画面
      const rs = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/${id}`)).data;
      setUserData(rs);
    }
    else { // id無　→　新規登録画面
      setUserData({ user_id: '', user_name: '', user_class: '', user_short: '', user_voice: '', password: '' });
      const target = document.getElementById('user_id');
      target.readOnly = false;
    }
  }, [id]);

  // セル変更イベント
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setUserData({ ...userData, [name]: value });
  }

  // DB登録：登録ボタン
  const handleRegisterClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    // 入力チェック
    if (!data.user_id) {
      window.alert('ユーザーIDを入力してください。');
      return;
    }
    if (!data.user_class) {
      window.alert('クラス区分を選択してください。');
      return;
    }
    if (!data.user_name) {
      window.alert('ユーザー名を入力してください。');
      return;
    }
    if (!data.user_short) {
      window.alert('ユーザー名（表示用）を入力してください。');
      return;
    }
    if (!data.password) {
      window.alert('パスワードを入力してください。');
      return;
    }
    // 確認メッセージ
    if (!window.confirm(`ユーザーID[${data.user_id}]を登録します。よろしいですか？`)) {
      return;
    }
    const params = {
      user_id: data.user_id,
      shop_id: constClass.SHOP_ID,
      user_class: data.user_class,
      user_name: data.user_name,
      user_short: data.user_short,
      user_voice: data.user_voice,
      password: data.password,
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/insert`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setLockData(false);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        history.replace(`${props.match.path}user`);
      }
    }
  }

  // DB更新：更新ボタン
  const handleUpdateClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    // 入力チェック
    if (!data.user_id) {
      window.alert('ユーザーIDを入力してください。');
      return;
    }
    if (!data.user_name) {
      window.alert('ユーザー名を入力してください。');
      return;
    }
    if (!data.user_short) {
      window.alert('ユーザー名(省略)を入力してください。');
      return;
    }
    if (!data.password) {
      window.alert('パスワードを入力してください。');
      return;
    }
    // 確認メッセージ
    if (!window.confirm(`ユーザマスタ[${data.user_id}:${data.user_name}]を更新します。よろしいですか？`)) {
      return;
    }
    const params = {
      user_id: data.user_id,
      user_name: data.user_name,
      user_class: data.user_class,
      user_short: data.user_short,
      user_voice: data.user_voice,
      password: data.password,
      upd_name: data.upd_name,
      upd_data: data.upd_data,
    };
    let res;
    try {
      setLockData(true);
      res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/user/update/${data.user_id}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshMaster();
      }
      setLockData(false);
    }
  }

  // DB削除：削除ボタン
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`ユーザマスタ[${data.user_id}:${data.user_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      user_id: data.user_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/delete`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        if (!window.alert(`ユーザー管理一覧画面に戻ります`)) {
          history.replace(`${props.match.path}user`);
          return;
        }
      }
      setLockData(false);
    }
  }

  // 音声再生：テストボタン
  const handleVoiceClick = async (data) => {
    const pitch = settingData.find(s => s.setting_type === constClass.SETTING.VOICE_PITCH) ? Number(settingData.find(s => s.setting_type === constClass.SETTING.VOICE_PITCH).setting_val) : 1;
    const rate = settingData.find(s => s.setting_type === constClass.SETTING.VOICE_RATE) ? Number(settingData.find(s => s.setting_type === constClass.SETTING.VOICE_RATE).setting_val) : 1;
    voice.play(`お待たせしました。1番のかた、${data.user_voice}へお越しください`, pitch, rate);
  }

  // 登録ボタン
  const registerButton = (data) => {
    if (id === undefined) {
      return (
        <button type="button"
          disabled={lockData}
          className={`btn btn-primary mx-1`}
          onClick={() => handleRegisterClick(data)}>
          登録
        </button>
      )
    }
  }

  // 更新ボタン
  const updateButton = (data) => {
    if (id) {
      return (
        <button type="button"
          disabled={lockData}
          className={`btn btn-primary mx-5`}
          onClick={() => handleUpdateClick(data)}>
          更新
        </button>
      )
    }
  }

  // 削除ボタン
  const deleteButton = (data) => {
    if (id) {
      return (
        <button type="button"
          disabled={lockData || data.user_id === null}
          className={`btn btn-primary mx-5`}
          onClick={() => handleDeleteClick(data)}>
          削除
        </button>
      )
    }
  }

  // 戻るボタン
  const backButton = () => {
    return (
      <button type="button"
        className={`btn btn-secondary mx-5`}
        onClick={() => history.goBack()}>
        戻る
      </button>
    )
  }

  // 初期実行処理トリガー
  useEffect(() => {
    function fetchData() {
      refreshMaster();
    }
    return fetchData();
  }, [refreshMaster]);

  return (
    <div className="container">
      {userData !== null && (
        <div className="row mb-3 col-10">

          {/* ユーザーID */}
          <label htmlFor="user_id" className="col-sm-3 col-form-label my-sm-3">ユーザーID</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" readOnly className="form-control" id={"user_id"} name="user_id" value={userData.user_id} onChange={handleChange}></input>
          </div>

          {/* クラス区分 */}
          <label htmlFor="user_id" className="col-sm-3 col-form-label my-sm-3">クラス区分</label>
          <div className="col-sm-9 a my-sm-3 text-left">
            <select className="custom-select" name="user_class" value={userData.user_class} onChange={handleChange}>
              <option key={constClass.CLASS_NAME.COUNTER} value={constClass.CLASS.COUNTER}>{constClass.CLASS_NAME.COUNTER}</option>
              <option key={constClass.CLASS_NAME.OTHER} value={constClass.CLASS.OTHER}>{constClass.CLASS_NAME.OTHER}</option>
            </select>
          </div>

          {/* ユーザー名 */}
          <label htmlFor="user_name" className="col-sm-3 col-form-label my-sm-3">ユーザー名</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id={"user_name"} name="user_name" value={userData.user_name} onChange={handleChange}></input>
          </div>

          {/* ユーザー名（表示用） */}
          <label htmlFor="user_short" className="col-sm-3 col-form-label my-sm-3">ユーザー名(表示用)</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id={"user_short"} name="user_short" value={userData.user_short} onChange={handleChange}></input>
          </div>

          {/* ユーザー名（音声用） */}
          <label htmlFor="user_short" className="col-sm-3 col-form-label my-sm-3">ユーザー名(音声用)</label>
          <div className="col-sm-7 my-sm-3">
            <input type="text" className="form-control" id={"user_voice"} name="user_voice" value={userData.user_voice ? userData.user_voice : ''} onChange={handleChange}></input>
          </div>
          <div className="col-sm-2 my-sm-3">
            <button type="button"
              disabled={lockData || userData.user_voice === null}
              className={`btn btn-secondary mx-1`}
              onClick={() => handleVoiceClick(userData)}>
              テスト
            </button>
          </div>

          {/* パスワード */}
          <label htmlFor="password" className="col-sm-3 col-form-label my-sm-3">パスワード</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id={"password"} name="password" value={userData.password} onChange={handleChange}></input>
          </div>

          {/* 更新ボタン/削除ボタン/戻るボタン  */}  {/* 登録ボタン/戻るボタン */}
          <div className="col-sm-9 my-sm-5 text-right">
            {updateButton(userData)}
            {deleteButton(userData)}
            {registerButton(userData)}
            {backButton()}
          </div>
        </div>
      )}

    </div>
  )


}
export default BackyardUserEdit